<template>
	<div class="border p-2">

		<h5>Scatter summary</h5>

		<table class="table">
			<thead>
			<tr>
				<th>Scatter #</th>
				<th>Hits</th>
				<th>Hits %</th>
				<th>Winnings</th>
				<th>Winnings %</th>
				<th>Avg winning</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(info, number_of_scatters) in test.Result.scatter_report">
				<td class="font-weight-bold">{{ number_of_scatters }} scatters</td>
				<td>{{ info.hits | numeral('0,0') }}</td>
				<td>{{ (info.hits / test.Result.spins * 100).toFixed(2) }}%</td>
				<td>{{ info.amount | numeral('0,0') }}</td>
				<td>{{ (info.amount / test.Result.win * 100) | numeral('0.0') }}%</td>
				<td>{{ (info.amount / info.hits) | numeral('0,0') }}</td>
			</tr>
			</tbody>
		</table>

	</div>
</template>

<script>
export default {
	name: "spinner-scatter-summary",
	props: {
		test: {}
	}
}
</script>

<style scoped>

</style>
