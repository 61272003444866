<template>
	<div v-if="test" class="mb-5">
		<div class="p-2 bg-light border mt-2">
			<div class="d-flex justify-content-between align-items-center">
				<h3 class="my-3 ml-2">Spinner #{{ test.Id }}</h3>
				<a :class="{'disabled text-light': !test.LogFile, 'text-primary': test.LogFile}"
					 class="btn btn-link"
					 @click.stop.capture target="_blank" :href="test.LogFile" v-if="test.SaveLogs || test.SaveSpinLogs">
					<i
							class="fa fa-download"></i> Download logs</a>
			</div>


			<div v-if="test.Result">
				<div class="progress">
					<div class="progress-bar" :style="{'width': `${(test.Result.spins / test.Spins) * 100}%`}"></div>
				</div>
			</div>

			<div class="p-2">
				<spinner-status-label :status="test.Status"></spinner-status-label>
				<div class="text-small text-danger" v-if="test.Status === 'error'"
						 v-text="test.ErrorMessage"></div>

				<div class="d-inline-flex">
					<template v-if="test.StartTime">
						<div class="text-small">Started</div>
						<div class="text-small mx-1" v-rel-date="new Date(Date.parse(test.StartTime))"></div>
						<div class="text-small mx-1"> -</div>
					</template>
					<template v-if="duration">
						<div class="text-small"> Total time</div>
						<div class="text-small mx-1">{{ duration }}</div>
					</template>

					<template v-if="test.Summary.speed > 0">
						<div class="text-small"> |</div>
						<div class="text-small font-weight-bold mx-1"
								 v-text="`${test.Summary.speed} spins/s`"></div>
					</template>
				</div>
			</div>


		</div>
		<template v-if="test.Result">

			<div class="p-2 border flex-grow-1 mr-2 mt-5">
				<h5>Summary</h5>
				<table class="table bg-white">
					<tr>
						<td class="font-weight-bold">Total bet</td>
						<td>{{ test.Result.bet | numeral('0,0') }}</td>
						<td class="font-weight-bold">Total win</td>
						<td>{{ test.Result.win | numeral('0,0') }}</td>
					</tr>
					<tr>
						<td colspan="2" class="font-weight-bold">RTP</td>
						<td colspan="2">{{ (test.Result.win / test.Result.bet) | numeral('0,0.00%') }}</td>
					</tr>
					<tr>
						<td class="font-weight-bold">Total spins</td>
						<td>{{ test.Result.spins | numeral('0,0') }}</td>
						<td class="font-weight-bold">Total hits</td>
						<td>{{ test.Result.hits | numeral('0,0') }}</td>
					</tr>
					<tr>
						<td class="font-weight-bold">Hit frequency</td>
						<td>{{ (test.Result.hits / test.Result.spins) | numeral('0.0%') }}</td>
						<td class="font-weight-bold">Average award</td>
						<td>{{ (test.Result.win / test.Result.hits) | numeral('0,0.00') }}</td>
					</tr>
				</table>
			</div>

			<spinner-winnings :winnings="typeof test.Result.winnings_stats === 'object' ? test.Result.winnings_stats : {}"
												v-model="value"></spinner-winnings>


			<spinner-test-summary :summary="test.Summary"></spinner-test-summary>

			<spinner-scatter-summary class="flex-grow-1" v-if="test.Result.scatter_report"
															 :test="test"></spinner-scatter-summary>

			<spinner-paylines-rtp-contribution class="mt-3" :total-win="test.Result.win" :paylines="test.Result.paylines"
																				 v-if="test.Result.paylines !== undefined && (typeof test.Result === 'object')"/>

			<spinner-payline-combos class="mt-3" :bet-per-line="test.Bet" :config="test.Configuration"
															v-if="test.Summary.paylines_combinations"
															:total-spins="test.Result.spins"
															:combinations="test.Summary.paylines_combinations"
															v-model="value"></spinner-payline-combos>
		</template>
	</div>
</template>

<script>
import SpinnerTestSummary from "@/components/spinner/spinner-test-summary";
import SpinnerWinnings from "@/components/spinner/spinner-winnings";
import SpinnerPaylineCombos from "@/components/spinner/spinner-payline-combos";
import SpinnerPaylinesRtpContribution from "@/components/spinner/SpinnerPaylinesRtpContribution";
import api from '@/api'
import SpinnerStatusLabel from "../../../../../../../components/spinner/spinner-status-label";
import moment from 'moment'
import SpinnerScatterSummary from "../../../../../../../components/spinner/spinner-scatter-summary";

export default {
	name: "_spinner_id",
	components: {
		SpinnerScatterSummary,
		SpinnerStatusLabel,
		SpinnerPaylinesRtpContribution, SpinnerPaylineCombos, SpinnerWinnings, SpinnerTestSummary
	},
	data: () => ({
		test: null,
		testResult: null,
		value: {
			grouped: true,
			showAmount: false,
			groups: [50, 100, 500],
			rounded: 1,
			displayWildDetails: false,
			reloadTimeout: null,
		}
	}),
	destroyed() {
		clearTimeout(this.reloadTimeout)
	},
	async mounted() {
		this.test = await api.Spinner.get(this.$route.params.spinner_id)
		await this.updateResult()
	},
	methods: {
		setupInterval() {
			this.reloadTimeout = setTimeout(this.updateResult, 1000)
		},
		async updateResult() {
			if (this.needUpdates) {
				this.test.Result = await api.Spinner.getSpinnerResult(this.$route.params.spinner_id)

				if (this.needUpdates) {
					this.setupInterval()
				}
			}
		}
	},
	computed: {
		duration: {
			lazy: true,
			get() {

				if (this.test.StartTime) {
					const then = this.test.StartTime;

					let now = this.test.EndTime;
					if (this.needUpdates) {
						now = new Date()
					}

					const ms = moment(now).diff(moment(then));
					const d = moment.duration(ms);
					const s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

					console.log('xxx')

					this.reloadTimeout;

					return s
				}
				return "00:00"
			}
		},
		needUpdates() {
			if (this.test) {
				return this.test.Status !== 'done'
			} else {
				return true;
			}
		}
	}
}
</script>

<style scoped>

</style>
